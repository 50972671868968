@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


a {
  color: inherit;
  text-decoration: none;
}

.card-arrow {
  position: relative;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes cardArrowAni {
  0% {
    left: 0px;
  }

  100% {
    left: 6px;
  }
}

.scroll-arrow {
  position: relative;
  animation-duration: 1s;
  animation-name: scrollAni;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes scrollAni {
  0% {
    top: 4px;
  }

  100% {
    top: -4px;
  }
}

.amazing-text-ani {
  position: relative;
  animation-duration: 1s;
  animation-name: amazingAni;
  animation-timing-function: linear;
}

@keyframes amazingAni {
  0% {
    font-size: 10px;
  }

  100% {
    font-size: 34px;
  }
}

.amazing-link-img {
  position: relative;
  animation-duration: 1s;
  animation-name: amazingAniLike;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: linear;
}

@keyframes amazingAniLike {
  0% {
    left: 10px;
  }

  100% {
    left: -10px;
  }
}