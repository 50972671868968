@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #ffffff;
	font-family: "Inter", sans-serif;
}
input:-internal-autofill-selected {
	appearance: menulist-button;
	background-image: none !important;
	background-color: #ffffff !important;
	color: fieldtext !important;
}
code {
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

a {
	color: inherit;
	text-decoration: none;
}
